import {graphql, useStaticQuery} from 'gatsby';
import {RichTextNode} from '@components/SectionCallout/RichTextCallout';
import {GatsbyImage, GatsbyImageProps} from 'gatsby-plugin-image';

export interface Tag {
  name: string;
  slug: string;
}

export interface Category {
  slug: string;
  name: string;
  header: string;
  subheader: string;
}

export interface Author {
  name: string;
  slug: string;
  jobTitle: string;
  photo: GatsbyImageProps;
  bio: RichTextNode;
  social: Social[];
}

export interface Social {
  linkType: string;
  url: {
    url: string;
  };
}

export interface LatestPost {
  excerpt: string;
  featured: boolean;
  title: string;
  url: string;
  publicationDate: string;
  authors: Author[];
  category: Category;
  tags: Tag[];
  image: {
    url: string;
    childImageSharp: GatsbyImageProps;
  };
}

export type LatestPostsData = LatestPost[] | null;

const LATEST_POSTS_QUERY = graphql`
  query LATEST_POSTS_QUERY($maxWidth: Int = 500, $maxHeight: Int = 270) {
    cms {
      latestPosts {
        seoTitle
        searchDescription
        excerpt
        featured
        title
        url
        publicationDate
        authors {
          name
          slug
          jobTitle
          photo {
            url
          }
          bio
          social {
            linkType
            url
          }
        }
        category {
          slug
          name
          header
          subheader
        }
        tags {
          name
          slug
        }
        image {
          ...WagtailImageSharpSource
          childImageSharp {
            gatsbyImageData(
              quality: 90
              width: $maxWidth
              height: $maxHeight
            )
          }
          height
          width
          focalPointX
          focalPointY
        }
      }
    }
  }
`;

export default function useLatestPosts(): LatestPostsData {
  const data = useStaticQuery(LATEST_POSTS_QUERY);
  if (
    !data ||
    !data.cms ||
    !data.cms.latestPosts ||
    data.cms.latestPosts.length === 0
  ) {
    return null;
  }
  return data.cms.latestPosts;
}
